<template>
  <div v-if="post">
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Beitrag bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="editSocialMediaPost">
              <CRow class="mb-3 align-items-center">
                <CCol sm="4">
                  <CInput
                    id="internal-name-input"
                    label="Interner Name"
                    name="name"
                    v-model="post.name"
                    placeholder="Ratgeber KW 51"
                    description="Geben Sie einen internen Namen für diesen Beitrag ein. Dieser dient nur der internen Organisation und wird nicht öffentlich angezeigt."
                    autocomplete="off"
                    required
                    v-c-tooltip="'Geben Sie einen eindeutigen Namen für den internen Gebrauch an.'"
                  />
                </CCol>
                <CCol sm="4">
                  <SocialMediaSelector
                    v-model="post.connection"
                    description="Wählen Sie die Social Media-Plattformen aus, auf denen dieser Beitrag geteilt werden soll."
                    v-c-tooltip="'Wählen Sie eine oder mehrere Plattformen aus, auf denen Ihr Beitrag veröffentlicht werden soll.'"
                  />
                </CCol>
                <CCol sm="4">
                  <DateTimePicker
                    id="publish-at-picker"
                    class="prt-social-media-date-time-picker"
                    label="Zeitpunkt des Versendes"
                    description="Legen Sie hier fest, wann Ihr Beitrag veröffentlicht werden soll - Datum und Uhrzeit."
                    :horizontal="false"
                    v-model="post.publishAt"
                    v-c-tooltip="'Bestimmen Sie einen spezifischen Zeitpunkt für die Veröffentlichung Ihres Beitrags.'"
                  />
                </CCol>
              </CRow>
              <CRow class="mb-3">
                <CCol sm="12">
                  <CTextarea
                    id="main-text-textarea"
                    label="Beitragstext"
                    v-model="post.content"
                    rows="6"
                    placeholder="Bearbeiten Sie den Beitragstext..."
                    required
                  />
                </CCol>
              </CRow>
              <CRow class="mb-3 button-row">
                <CCol sm="auto">
                  <CButton @click="generateAlternative" color="warning" class="mr-2" :disabled="isGenerating">
                    Alternative generieren lassen
                  </CButton>
                  <CButton @click="showKIPopup" color="info" :disabled="isGenerating">
                    KI-Tool öffnen
                  </CButton>
                </CCol>
              </CRow>
              <CRow class="mb-3">
                <CCol sm="12">
                  <ImageUploadAndSearch
                    :uploadLabel="'Bild hochladen'"
                    :searchLabel="'Stock-Fotos auswählen'"
                    :initialImage="post.imageUrl"
                    @image-upload="handleImageUpload"
                    @image-select="handleImageSelect"
                    @image-remove="handleImageRemove"
                  />
                </CCol>
              </CRow>
              <CRow class="mb-3 button-row">
                <CCol sm="auto" class="ml-auto">
                  <CButton type="submit" color="success" class="ml-2">
                    Änderungen speichern
                  </CButton>
                  <CButton v-if="enableDelete" @click="deleteHandler" color="danger" class="ml-2">
                    Löschen
                  </CButton>
                </CCol>
              </CRow>
              <CRow class="mb-3" v-if="isGenerating">
                <CCol>
                  <div class="loading-indicator">
                    <div class="spinner"></div>
                    <p>Inhalt wird generiert...</p>
                  </div>
                </CCol>
              </CRow>
            </CForm>
            <KIPopup
              :show="showPopup"
              @close="showPopup = false"
              @apply="applyKIGeneratedContent"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import KIPopup from "@/views/base/social-media/KIPopup.vue";
import ImageUploadAndSearch from "@/components/ImageUploadAndSearch.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import SocialMediaSelector from "@/components/SocialMediaSelector.vue";
import { CRow, CCol, CCard, CCardHeader, CCardBody, CForm, CTextarea, CButton, CInput } from '@coreui/vue';

export default {
  name: 'SocialMediaPostEdit',
  components: { CRow, CCol, CCard, CCardHeader, CCardBody, CForm, CTextarea, CButton, KIPopup, ImageUploadAndSearch, DateTimePicker, SocialMediaSelector, CInput },
  data() {
    return {
      processing: false,
      ready: false,
      showPopup: false,
      post: null,
      enableDelete: true,
      isGenerating: false
    }
  },
  created() {
    axios.get('/socialMedia/posts/' + this.$route.params.id)
        .then(response => {
          const data = response.data;
          if (data.connection) {
            if (data.connection.name === 'LinkedIn') {
              data.connection = { icon: "cib-linkedin", id: data.connection.id, title: data.connection.name };
            } else {
              data.connection = { icon: "cib-facebook", id: data.connection.id, title: data.connection.name };
            }
          }
          this.post = data;
          this.post.connectionId = data.connection.id; // Ensure connectionId is set
          this.ready = true;
        }).catch(err => console.error(err));
  },
  methods: {
    showKIPopup() {
      this.showPopup = true;
    },
    generateAlternative() {
      this.isGenerating = true;
      const requestData = {
        useEmojis: true, 
        voiceStyle: "INFORMATIONAL",
        keywords: this.post.keywords, 
        title: this.post.title,
        mainContent: this.post.content,
        notes: this.post.notes,
        cta: this.post.cta,
        generateAlternative: true
      };

      axios.post('/socialMedia/generateByAi', requestData)
        .then(response => {
          this.post.content = response.data.result || 'Der KI-generierte Inhalt konnte nicht abgerufen werden.';
        })
        .catch(err => {
          this.$toaster.makeToast('Fehler!', 'Fehler beim Generieren des Inhalts: ' + err.response.data.message);
        })
        .finally(() => {
          this.isGenerating = false;
        });
    },
    applyKIGeneratedContent(content) {
      this.post.content = content;
      this.showPopup = false;
    },
    editSocialMediaPost() {
      this.processing = true;
      const updateData = {
        ...this.post,
        connectionId: this.post.connectionId.id || this.post.connection.id,
        imageUrl: typeof this.post.imageUrl === 'object' ? this.post.imageUrl.url : this.post.imageUrl // Ensure imageUrl is a string
      };
      axios.put('/socialMedia/posts/' + this.$route.params.id, updateData)
          .then(() => {
            this.$router.push({name: 'SocialMediaPosts', params: { saved: '1' }});
            this.$toaster.makeToast('Erfolgreich!', "<b>Änderungen wurden gespeichert</b>");
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message);
            } else {
              this.$toaster.makeToast('Error!', err.message);
            }
          })
          .finally(() => {
            this.processing = false;
          });
    },
    deleteHandler() {
      const postId = this.$route.params.id.trim();
      if (!postId) {
        this.$toaster.makeToast('Fehler!', 'Beitrags-ID fehlt.');
        return;
      }

      const isUnsplashImage = this.post.imageUrl && this.post.imageUrl.includes('unsplash.com');

      if (isUnsplashImage) {
        axios.delete(`/socialMedia/posts/${postId}`)
          .then(() => {
            this.$router.push({ name: 'SocialMediaPosts' });
            this.$toaster.makeToast('Erfolgreich!', '<b>Beitrag wurde gelöscht</b>');
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', `<b>${err.response.data.code}</b><br>${err.response.data.message}`);
            } else {
              this.$toaster.makeToast('Error!', err.message);
            }
          });
      } else {
        const deleteUrl = `/socialMedia/posts/${postId}`;
        axios.delete(deleteUrl)
          .then(() => {
            this.$router.push({ name: 'SocialMediaPosts' });
            this.$toaster.makeToast('Erfolgreich!', '<b>Beitrag wurde gelöscht</b>');
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', `<b>${err.response.data.code}</b><br>${err.response.data.message}`);
            } else {
              this.$toaster.makeToast('Error!', err.message);
            }
          });
      }
    },
    handleImageUpload(imageUrl) {
      this.post.imageUrl = imageUrl;
    },
    handleImageSelect(imageUrl) {
      this.post.imageUrl = imageUrl;
    },
    handleImageRemove() {
      this.post.imageUrl = '';
    }
  }
}
</script>

<style scoped>
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.loading-indicator p {
  margin-left: 10px;
  color: #007bff;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Ensure consistent height for input elements */
.prt-social-media-date-time-picker .form-group,
.social-media-selector .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}



.upload-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.search-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.search-btn:hover {
  background-color: #0056b3;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-result.selected {
  border: 2px solid #007bff;
}

.search-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.image-container {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.result-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.select-btn.selected-btn {
  background-color: #007bff;
}

.select-btn:hover {
  background-color: #218838;
}

.select-btn.selected-btn:hover {
  background-color: #0056b3;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}
</style>